import React from "react"

const ErrorPage = () => {
  return (
    <div id="app">
      <p>oops...</p>
    </div>
  )
}

export default ErrorPage
